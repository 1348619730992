import { Fragment, useCallback } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { Button, Flex, Card, Tab, TabList, Tabs, TabPanel, TabPanels, Box, Spinner, GridItem, Grid } from '@tradener/lumen';

import useRestriction from '~/hooks/useRestriction';
import DownloadIcon from '~/theme/icons/DownloadIcon';
import UploadIcon from '~/theme/icons/UploadIcon';
import Header from '~/theme/layout/Header';

import { DetailsModal } from './components/DetailsModal';
import { EditLimitsModal } from './components/EditLimitsModal';
import { Fallback } from './components/Fallback';
import { HeaderItem } from './components/HeaderItem';
import { Item } from './components/Item';
import { SearchField } from './components/SearchField';
import { Skeleton } from './components/Skeleton';
import { Td } from './components/Td';
import { useOperationLimits, withOperationLimits } from './hooks/useOperationLimits';

const OperationLimits: React.FC = () => {
  const { restrict } = useRestriction();
  const {
    data,
    filter,
    offset,
    loading,
    showDetails,
    loadingUpload,
    showEditLimits,
    loadingDownload,
    upload,
    onClose,
    download,
    fetchMore,
    setOffset,
  } = useOperationLimits();
  const hasMore = Number(data?.operationLimitsList?.length) % 50 === 0;

  const handleLoadMore = useCallback(() => {
    if (loading) return;

    fetchMore({ variables: { offset: offset + 50, filter } });
    setOffset(offset + 50);
  }, [offset, fetchMore, filter, setOffset, loading]);

  return (
    <>
      <Flex flexDirection="column">
        <Header px="4" columnGap="3" align="center" />
        <Box p={5}>
          <Card display="flex" borderRadius="xl" p="0" width="full" flex={1}>
            <Tabs w="full">
              <Box borderBottom="solid 1px" borderColor="gray.100">
                <TabList height={14} px={5}>
                  <Tab fontSize="body2-regular">Contas</Tab>
                  <Tab fontSize="body2-regular" isDisabled>
                    Traders
                  </Tab>
                </TabList>
              </Box>
              <TabPanels>
                <TabPanel p={0}>
                  <Flex direction="column" rowGap="6" width="full" padding="5">
                    <Flex justify="space-between" align="center" gap="2" flexWrap="wrap">
                      <SearchField />
                      <Flex direction="row" gap="3" width="fit-content">
                        {restrict(
                          'OPERATION_LIMITS_UPSERT',
                          <>
                            <Button
                              variant="outline"
                              colorScheme="gray"
                              size="sm"
                              leftIcon={<UploadIcon />}
                              onClick={() => document.getElementById('fileInput')?.click()}
                              isLoading={loadingUpload}
                            >
                              Importar
                            </Button>
                            <input
                              type="file"
                              id="fileInput"
                              accept=".csv"
                              style={{ display: 'none' }}
                              onChange={(e) => {
                                const file = e.target.files?.[0];

                                if (file) {
                                  upload(file);
                                }
                                e.currentTarget.value = '';
                              }}
                            />
                          </>,
                        )}
                        {restrict(
                          'OPERATION_LIMITS_EXPORT',
                          <Button
                            variant="outline"
                            colorScheme="gray"
                            size="sm"
                            onClick={() => download()}
                            leftIcon={<DownloadIcon />}
                            isLoading={loadingDownload}
                          >
                            Exportar
                          </Button>,
                        )}
                      </Flex>
                    </Flex>
                    <Flex width="full" direction="column" flex="1">
                      <InfiniteScroll
                        dataLength={data?.operationLimitsList?.length ?? 0}
                        hasMore={(data?.operationLimitsList?.length || 0) % 50 === 0}
                        next={handleLoadMore}
                        style={{ scrollbarWidth: 'none' }}
                        loader={
                          hasMore && (data?.operationLimitsList?.length || 0) > 0 ? (
                            <GridItem>
                              <Td colSpan={6}>
                                <Flex alignItems="center" justifyContent="center" p={6} w="100%">
                                  <Spinner color="orange.500" size="lg" />
                                </Flex>
                              </Td>
                            </GridItem>
                          ) : null
                        }
                      >
                        {!loading && data?.operationLimitsList?.length === 0 ? (
                          <Fallback py={16} message="Sem dados para os filtros aplicados!" />
                        ) : (
                          <Grid gridTemplateColumns="4fr 1fr 1fr 1fr .3fr .1fr" width="full">
                            <Fragment>
                              <HeaderItem>Conta/CNPJ</HeaderItem>
                              <HeaderItem>Periodo</HeaderItem>
                              <HeaderItem>Limite compra</HeaderItem>
                              <HeaderItem>Limite venda</HeaderItem>
                              <HeaderItem textAlign="center">Ativo</HeaderItem>
                              <HeaderItem p={0} />
                            </Fragment>
                            {loading ? (
                              <Skeleton loading />
                            ) : (
                              <>
                                {data?.operationLimitsList.map((item, index) => {
                                  if (index === data?.operationLimitsList.length - 1) {
                                    return <Item key={item.taxId} {...item} />;
                                  }

                                  return <Item key={item.taxId} {...item} />;
                                })}
                              </>
                            )}
                          </Grid>
                        )}
                      </InfiniteScroll>
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Fallback py={16} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </Box>
      </Flex>
      <DetailsModal isOpen={showDetails} onClose={onClose} />
      <EditLimitsModal isOpen={showEditLimits} onClose={onClose} />
    </>
  );
};

export default withOperationLimits(OperationLimits);
