import React, { Fragment, useEffect, useState } from 'react';

import { Flex, Switch, Text, Highlight, IconButton, EditIcon, Skeleton, Box, Spinner } from '@tradener/lumen';

import useRestriction from '~/hooks/useRestriction';

import useLockOperationLimits from '../../hooks/queries/useLockOperationLimit';
import { useOperationLimits } from '../../hooks/useOperationLimits';
import { MODAL } from '../../types/Modal';
import { Td } from '../Td';

export interface ItemProps {
  resourceId?: string;
  accountName?: string;
  taxId?: string;
  totalMonths?: number;
  purchaseLimit?: number;
  saleLimit?: number;
  lockOperationLimit?: boolean;
  loading?: boolean;
  ref?: React.Ref<HTMLTableRowElement>;
}
export const Item = React.forwardRef<HTMLTableRowElement, ItemProps>(
  ({ resourceId, accountName, taxId, totalMonths, purchaseLimit, saleLimit, loading, lockOperationLimit }, ref) => {
    const purchaseLimitValue = `${purchaseLimit ? purchaseLimit.formatCurrency() : '-'}`;
    const saleLimitValue = `${saleLimit ? saleLimit.formatCurrency() : '-'}`;
    const [loadingLock, setLoadingLock] = useState(false);
    const [lockState, setLockState] = useState(false);
    const { onOpen } = useOperationLimits();
    const { restrict, hasPermission } = useRestriction();
    const { switchState } = useLockOperationLimits();

    useEffect(() => {
      setLockState(lockOperationLimit || false);
    }, [lockOperationLimit]);

    const handleSwitch = async () => {
      setLoadingLock(true);
      const changed = await switchState(resourceId || '').finally(() => setLoadingLock(false));

      setLockState(changed ? !lockState : lockState);
    };

    return (
      <Fragment>
        <Td p={3} onClick={() => onOpen(resourceId || '', MODAL.DETAILS)} cursor="pointer">
          <Flex ref={ref} flexDir="column" gap={1}>
            <Skeleton borderRadius={'md'} isLoaded={!loading} maxW={loading ? '70%' : 'auto'}>
              <Text textStyle="body2-regular" textOverflow="ellipsis" overflow="hidden" whiteSpace="no-wrap" noOfLines={3}>
                {accountName ? accountName : '-'}
              </Text>
            </Skeleton>
            <Skeleton borderRadius={'md'} isLoaded={!loading} maxW={loading ? '40%' : 'auto'}>
              <Text textStyle="caption1-regular" color="gray.500">
                {taxId ? taxId?.formatTaxId() ?? '-' : '-'}
              </Text>
            </Skeleton>
          </Flex>
        </Td>
        <Td p={3}>
          <Skeleton borderRadius={'md'} isLoaded={!loading}>
            <Text textStyle="body2-regular">{totalMonths ? `${totalMonths} meses` : '-'}</Text>
          </Skeleton>
        </Td>
        <Td p={3}>
          <Skeleton borderRadius={'md'} isLoaded={!loading}>
            <Text textStyle="body2-regular" color="gray.500">
              <Highlight query={purchaseLimitValue} styles={{ color: 'gray.900' }}>
                {`${purchaseLimitValue}`}
              </Highlight>
            </Text>
          </Skeleton>
        </Td>
        <Td p={3}>
          <Skeleton borderRadius={'md'} isLoaded={!loading}>
            <Text textStyle="body2-regular" color="gray.500">
              <Highlight query={saleLimitValue} styles={{ color: 'gray.900' }}>
                {`${saleLimitValue}`}
              </Highlight>
            </Text>
          </Skeleton>
        </Td>
        <Td p={3} textAlign="center">
          <Skeleton borderRadius={'xl'} isLoaded={!loading}>
            {loadingLock ? (
              <Spinner color="orange.500" size="md" />
            ) : (
              <Switch
                isDisabled={!hasPermission('OPERATION_LIMITS_LOCK')}
                isChecked={lockState}
                onChange={() => handleSwitch()}
              />
            )}
          </Skeleton>
        </Td>
        <Td p={0} textAlign="center">
          {restrict(
            'OPERATION_LIMITS_UPSERT',
            <Box p={3}>
              <Skeleton isLoaded={!loading}>
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  icon={<EditIcon fontSize={24} />}
                  aria-label={'Editar'}
                  onClick={() => onOpen(resourceId || '', MODAL.EDIT)}
                />
              </Skeleton>
            </Box>,
          )}
        </Td>
      </Fragment>
    );
  },
);
