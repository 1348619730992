import { gql } from '@apollo/client';

export interface PayloadProps {
  accountId: string;
}

export interface ResponseProps {
  lockOperationLimits: {
    status: string;
  };
}

const LOCK_OPERATION_LIMITS = gql`
  mutation lockOperationLimits($accountId: ID!) {
    lockOperationLimits(input: { accountId: $accountId }) {
      status
    }
  }
`;

export default LOCK_OPERATION_LIMITS;
