import { useMutation } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import LOCK_OPERATION_LIMITS, { ResponseProps, PayloadProps } from '@graphql/mutation/operationLimits/lock';

interface LockOperationLimitsProps {
  switchState: (resourceId: string) => Promise<boolean>;
}

const useLockOperationLimits = () => {
  const toast = useToast();

  const [upsert] = useMutation<ResponseProps, PayloadProps>(LOCK_OPERATION_LIMITS, {
    onError: ({ message }) => {
      toast({ title: 'Erro', status: 'error', description: message || 'Erro ao alterar trava de limite de operação!' });
    },
  });

  const switchState = async (resourceId: string) =>
    upsert({ variables: { accountId: resourceId } })
      .then(({ data }) => {
        if (data?.lockOperationLimits?.status === 'ok') {
          return true;
        } else {
          return false;
        }
      })
      .catch(() => false);

  return {
    switchState,
  } as LockOperationLimitsProps;
};

export default useLockOperationLimits;
