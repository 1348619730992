import { createContext, memo, useContext, useEffect, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import { ResponseProps as DetailsResponseProps, OperationLimitDetailProps } from '@graphql/query/operationLimits/detail';
import {
  ResponseProps as ListResponseProps,
  PayloadProps as ListPayloadProps,
  ResponseProps,
  PayloadProps,
} from '@graphql/query/operationLimits/list';

import { Filter } from '../types/Filter';
import { MODAL } from '../types/Modal';
import { OPERATION_LIMITS_TYPE } from '../types/OperationTypes';
import useDetailsOperationLimits from './queries/useDetailsOperationLimits';
import useDownloadOperationLimits from './queries/useDownloadOperationLimits';
import useEditOperationLimits from './queries/useEditOperationLimits';
import useListOperationLimits, { initialFilterState } from './queries/useListOperationLimits';
import useUploadOperationLimits from './queries/useUploadOperationLimits';
import useUpsertOperationLimits from './queries/useUpsertOperationLimits';

interface OperationLimitsContextProps {
  filter: Filter;
  offset: number;
  loading: boolean;
  accountId: string;
  showDetails: boolean;
  offSetEnded: boolean;
  loadingUpsert: boolean;
  loadingUpload: boolean;
  showEditLimits: boolean;
  loadingDetails: boolean;
  loadingDownload: boolean;
  loadingEditDetails: boolean;
  data: ListResponseProps | undefined;
  dataDetails: DetailsResponseProps | undefined;
  dataEditDetails: OperationLimitDetailProps | undefined;
  onSave: () => void;
  onClose: () => void;
  refetch: () => void;
  download: () => void;
  addLimitRange: () => void;
  upload: (file: File) => void;
  onSearch: (search: string) => void;
  openEditLimits: (id: string) => void;
  removeLimitRange: (index: number) => void;
  onOpen: (id: string, type: MODAL) => void;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  setAccountId: React.Dispatch<React.SetStateAction<string>>;
  editField: (index: number, field: string, value: number) => void;
  fetchMore: (options: { variables: Partial<PayloadProps> }) => Promise<ApolloQueryResult<ResponseProps>>;
  setFilter: React.Dispatch<React.SetStateAction<typeof initialFilterState>>;
}

const Context = createContext<OperationLimitsContextProps | undefined>(undefined);

const useOperationLimits = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useOperationLimits must be used within a Provider');
  }

  return context;
};

const Provider: React.ComponentType<any> = (props) => {
  const toast = useToast();
  const { data, loading: loadingList, refetch: refetchList, fetchMore } = useListOperationLimits();
  const { upload: uploadMutation, loading: loadingUpload } = useUploadOperationLimits();
  const { upsert: upsertMutation, loading: loadingUpsert } = useUpsertOperationLimits();
  const { download, loading: loadingDownload } = useDownloadOperationLimits();
  const { data: dataDetails, loading: loadingDetails, setAccountId: setAccountIdDetails } = useDetailsOperationLimits();
  const {
    data: dataEditDetailsResponse,
    loading: loadingEditDetails,
    setAccountId: setAccountIdEditDetails,
  } = useEditOperationLimits();

  const [filter, setFilter] = useState(initialFilterState);
  const [offset, setOffset] = useState<number>(0);
  const [accountId, setAccountId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showEditLimits, setShowEditLimits] = useState<boolean>(false);
  const [dataEditDetails, setDataEditDetails] = useState<OperationLimitDetailProps>();
  const [editedData, setEditedData] = useState<OperationLimitDetailProps>();

  const onOpen = (id: string, type: MODAL) => {
    setAccountId(id);
    if (type === MODAL.DETAILS) {
      setShowDetails(true);
      setAccountIdDetails(id);
    }
    if (type === MODAL.EDIT) {
      setShowEditLimits(true);
      setAccountIdEditDetails(id);
    }
  };

  const onSearch = (search: string) => {
    setFilter({ ...filter, search });
    setOffset(0);
    refetch({ filter: { ...filter, search }, offset: 0 });
  };

  const onSave = () => {
    if (!editedData) return;

    upsertMutation({
      variables: {
        resourceId: accountId,
        operationLimits: editedData.series
          ?.filter((serie) => serie.purchaseLimit !== 0 || serie.saleLimit !== 0)
          .map((serie) => ({
            purchaseLimit: serie.purchaseLimit,
            saleLimit: serie.saleLimit,
            startRange: serie.startRange,
            endRange: serie.endRange,
          })),
      },
    }).then(() => {
      onClose();
      refetch({ filter: { ...filter }, offset: 0 });
    });
  };

  const upload = (file: File) => {
    if (file.type !== 'text/csv') {
      toast({ title: 'Erro', status: 'error', description: 'O arquivo deve ser do tipo CSV!' });

      return;
    }

    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

    if (file.size > maxSizeInBytes) {
      toast({ title: 'Erro', status: 'error', description: 'O tamanho do arquivo não deve exceder 5 MB!' });

      return;
    }

    uploadMutation({ variables: { file: file } }).finally(() => refetch({ filter: { ...filter }, offset: 0 }));
  };

  const onClose = () => {
    setAccountId('');
    setAccountIdDetails('');
    setAccountIdEditDetails('');
    setDataEditDetails(undefined);
    setShowEditLimits(false);
    setShowDetails(false);
  };

  const openEditLimits = (id: string) => {
    setAccountId(id);
    setShowEditLimits(true);
  };

  const addLimitRange = () => {
    const lastEndRange = editedData?.series?.[editedData.series.length - 1]?.endRange || 0;
    const newField = (prev) =>
      prev && {
        ...prev,
        series: [
          ...(prev.series || []),
          {
            purchaseLimit: 0,
            saleLimit: 0,
            competence: '',
            period: 0,
            startRange: lastEndRange + 1,
            endRange: lastEndRange + 1,
          },
        ],
      };

    setEditedData(newField);
    setDataEditDetails(newField);
  };

  const editField = (index: number, field: string, value: number) => {
    setEditedData((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        series: prev.series?.map((serie, i) => {
          if (i === index) {
            return {
              ...serie,
              [field]: value,
            };
          }

          return serie;
        }),
      };
    });
  };

  const removeLimitRange = (index: number) => {
    const remainingRanges = (prev) => {
      if (!prev) return null;

      return {
        ...prev,
        series: prev.series?.filter((_, i) => i !== index),
      };
    };

    setEditedData(remainingRanges);
    setDataEditDetails(remainingRanges);
  };

  const downloadUrl = () => {
    download({
      variables: {
        filter: { type: 'COUNTERPART' as OPERATION_LIMITS_TYPE },
      },
    });
  };

  const refetch = (variables?: Partial<ListPayloadProps> | undefined) => {
    setLoading(true);
    refetchList(variables).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!dataEditDetailsResponse) return;
    setDataEditDetails(dataEditDetailsResponse?.operationLimitsDetail);
    setEditedData(dataEditDetailsResponse?.operationLimitsDetail);
  }, [dataEditDetailsResponse]);

  useEffect(() => {
    setLoading(loadingList);
  }, [loadingList]);

  const hookProps: OperationLimitsContextProps = {
    data,
    dataDetails,
    loading,
    filter,
    offset,
    accountId,
    showDetails,
    loadingUpload,
    loadingUpsert,
    loadingDetails,
    showEditLimits,
    dataEditDetails,
    loadingDownload,
    offSetEnded: false,
    loadingEditDetails,
    onSave,
    onOpen,
    upload,
    refetch,
    onSearch,
    onClose,
    editField,
    setOffset,
    setFilter,
    fetchMore,
    setAccountId,
    addLimitRange,
    openEditLimits,
    removeLimitRange,
    download: downloadUrl,
  };

  return <Context.Provider value={hookProps} {...props} />;
};

const withOperationLimits = <T,>(Component: React.ComponentType<T>) =>
  memo((props: React.PropsWithChildren<T>) => (
    <Provider {...props}>
      <Component {...props} />
    </Provider>
  ));

export { withOperationLimits, useOperationLimits };
