import { useState } from 'react';

import { useQuery } from '@apollo/client';
import { useToast } from '@tradener/lumen';

import DETAILS_OPERATION_LIMITS, { ResponseProps, PayloadProps } from '@graphql/query/operationLimits/detail';

interface DetailsOperationLimitsProps {
  loading: boolean;
  data: ResponseProps | undefined;
  setAccountId: (value: string) => void;
}

const useDetailsOperationLimits = () => {
  const [accountId, setAccountId] = useState<string>('');
  const toast = useToast();

  const { data, loading } = useQuery<ResponseProps, PayloadProps>(DETAILS_OPERATION_LIMITS, {
    fetchPolicy: 'cache-first',
    variables: { accountId, grouped: false },
    skip: accountId.length === 0,
    onError: ({ message }) => {
      if (message !== 'not_found') {
        toast({ title: 'Erro', status: 'error', description: message || 'Erro ao carregar detalhes!' });
      }
    },
  });

  const hookProps: DetailsOperationLimitsProps = {
    loading,
    data,
    setAccountId,
  };

  return hookProps;
};

export default useDetailsOperationLimits;
