import gql from 'graphql-tag';

import { OPERATION_LIMITS_TYPE } from '~/pages/OperationLimits/types/OperationTypes';

export interface PayloadProps {
  filter: {
    type: OPERATION_LIMITS_TYPE;
    search?: string;
  };
  offset?: number;
  limit?: number;
}

export interface OperationLimitProps {
  resouceId?: string;
  accountName?: string;
  taxId?: string;
  totalMonths?: number;
  purchaseLimit?: number;
  saleLimit?: number;
  lockOperationLimit?: boolean;
}

export interface ResponseProps {
  operationLimitsList: OperationLimitProps[];
}

const LIST_OPERATION_LIMITS = gql`
  query operationLimitsList($offset: Int, $limit: Int, $filter: OperationLimitsListFilter!) {
    operationLimitsList(filter: $filter, limit: $limit, offset: $offset) {
      resourceId
      accountName
      taxId
      totalMonths
      purchaseLimit
      saleLimit
      lockOperationLimit
    }
  }
`;

export default LIST_OPERATION_LIMITS;
