import {
  Flex,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Thead,
  Text,
  Skeleton as LumenSkeleton,
  Tr,
} from '@tradener/lumen';

import { useOperationLimits } from '../../hooks/useOperationLimits';
import { Fallback } from '../Fallback';
import { Skeleton } from './Skeleton';
import { Item } from './Table/Item';
import { Th } from './Table/Th';

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DetailsModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { dataDetails, loadingDetails } = useOperationLimits();

  return (
    <ChakraModal isCentered isOpen={isOpen} size="6xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader py={6}>Limites</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" gap={5} w="full" pb={5}>
            <Flex flexDir="column" gap={1}>
              <LumenSkeleton borderRadius={'md'} isLoaded={!loadingDetails} w={loadingDetails ? '100' : 'auto'}>
                <Text textStyle="body1-medium" textOverflow="ellipsis" overflow="hidden" whiteSpace="no-wrap" noOfLines={3}>
                  {dataDetails?.operationLimitsDetail?.accountName ?? '-'}
                </Text>
              </LumenSkeleton>
              <LumenSkeleton borderRadius={'md'} isLoaded={!loadingDetails} w={loadingDetails ? '60' : 'auto'}>
                <Text textStyle="body2-regular" color="gray.500">
                  {dataDetails?.operationLimitsDetail?.taxId?.formatTaxId() ?? '-'}
                </Text>
              </LumenSkeleton>
            </Flex>
            {!loadingDetails && dataDetails?.operationLimitsDetail?.series?.length === 0 ? (
              <Fallback py={16} message="Sem limites cadastrados para empresa!" />
            ) : (
              <Table>
                <Thead>
                  <Tr>
                    <Th minW={40}>Prazo</Th>
                    <Th>Limite compra</Th>
                    <Th>Limite venda</Th>
                    <Th>Consumido compra</Th>
                    <Th>Saldo compra</Th>
                    <Th>Consumido venda</Th>
                    <Th>Saldo venda</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loadingDetails ? (
                    <Skeleton />
                  ) : (
                    dataDetails?.operationLimitsDetail?.series?.map((item) => <Item key={item.period} {...item} />)
                  )}
                </Tbody>
              </Table>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
